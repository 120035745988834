import { returnPosturePayload } from "@amzn/siteconnect-idp-posture-assessment/dist/lib/network";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { PostureResult } from "../../../common/models";
import { copyClipboard, getPostureResult } from "../../../common/utils";
import { Alert, Button, Input, SpaceBetween } from "@amzn/awsui-components-react";
import Collapsible from 'react-collapsible'; // https://github.com/glennflanagan/react-collapsible#readme

interface PostureTypeResult {
    reason?: string
    result?: string
}

export const TroubleshootingPage = () => {
    const codeText = 'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install'

  const [postureResult, setPostureResult] = useState<PostureResult>();
  const [loading, setLoading] = useState<boolean>(true)
  const [userId, setUserId] = useState<string>();

  const [postureHeader, setPostureHeader] = useState<PostureTypeResult>({reason: undefined, result: undefined})
  const [supportedOS, setSupportedOS] = useState<PostureTypeResult>({reason: undefined, result: undefined})
  const [OSType, setOSType] = useState<PostureTypeResult>({reason: undefined, result: undefined})
  const [firewall, setFirewall] = useState<PostureTypeResult>({reason: undefined, result: undefined})
  const [antivirus, setAntivirus] = useState<PostureTypeResult>({reason: undefined, result: undefined})
  const [structure, setStructure] = useState<PostureTypeResult>({reason: undefined, result: undefined})


  useEffect(() => {
    const getPayload = async () => {
        setLoading(true)
        const payload = JSON.stringify(await returnPosturePayload());
        getPostureResult(payload, userId).then(r => {    
          setPostureResult(r.data)
        }).catch(err => {
          setPostureResult({ result: false, reason: 
            {'os.type': err.response.data,
             'os.version': err.response.data,
             antivirus: err.response.data,
             firewall: err.response.data,
             structure: err.response.data
            }});
        }).finally(() => {
          setLoading(false)
        })
      };
    if (loading) {
        getPayload()
    }
  }, [loading, userId])

  useEffect(() => {
    const createErrorText = (failureReason: string | undefined) => {
        if (failureReason === undefined) {
            return undefined
        } 
        return "- " + failureReason 
    }

    if (!loading) {
        let postureText;
        let structureText = postureResult?.reason.structure;
        if (postureResult?.result) {
            postureText = "Suceeded!"
        } else {
            postureText = 'Failed! Please fix the issue(s) below.'
        }
        
        setPostureHeader({result: postureResult?.result ? "success" : "fail", reason: createErrorText(postureText)})
        if (structureText !== undefined && structureText.includes('Missing SiteConnect Web Server')) {
            // we want to use a better formatted text for missing web server errors
            let configureText = "Please fix your local web server. If not installed, follow instructions in the install page. If already installed, follow instructions in the updating page."
            setStructure({result: postureResult?.reason.structure ? "fail" : "success", reason: createErrorText(configureText)})
            setSupportedOS({result: postureResult?.reason["os.version"] ? "fail" : "success", reason: createErrorText(configureText)})
            setOSType({result: postureResult?.reason["os.type"] ? "fail" : "success", reason: createErrorText(configureText)})
            setFirewall({result: postureResult?.reason.firewall ? "fail" : "success", reason: createErrorText(configureText)})
            setAntivirus({result: postureResult?.reason.antivirus ? "fail" : "success", reason: createErrorText(configureText)})
            return
        }
        setStructure({result: postureResult?.reason.structure ? "fail" : "success", reason: createErrorText(structureText)})
        setSupportedOS({result: postureResult?.reason["os.version"] ? "fail" : "success", reason: createErrorText(postureResult?.reason['os.version'])})
        setOSType({result: postureResult?.reason["os.type"] ? "fail" : "success", reason: createErrorText(postureResult?.reason['os.type'])})
        setFirewall({result: postureResult?.reason.firewall ? "fail" : "success", reason: createErrorText(postureResult?.reason.firewall)})
        setAntivirus({result: postureResult?.reason.antivirus ? "fail" : "success", reason: createErrorText(postureResult?.reason.antivirus)})
    } else {
        setPostureHeader({result: undefined, reason: undefined})
        setStructure({result: undefined, reason: undefined})
        setSupportedOS({result: undefined, reason: undefined})
        setOSType({result: undefined, reason: undefined})
        setFirewall({result: undefined, reason: undefined})
        setAntivirus({result: undefined, reason: undefined})
    }
  }, [postureResult, loading])

    return (
    <body>
        <h1>Windows Troubleshooting</h1>

        <h2>SiteConnect Connection Issues</h2>
        <SpaceBetween direction="vertical" size="xs">
        <h3 className={postureHeader.result}>Your Posture Status {postureHeader.reason}</h3>
        <div>Please note that all sections should be <text style={{color: 'green'}}> green</text>. <b>This is required or your connection will be unsuccessful. 
        You need to have the most recent version of the web server installed. If you do not or are unsure, go back to {" "} 
      <Link to="/windows/install.html">
        the install page
      </Link>{" "}
      and follow the install instructions.</b></div>
            {postureResult?.result === false && <>
            <Alert type="info">
                If you need help debugging posture issues, you may enter your
                email before checking posture to help our team identify your
                posture. Note that this is an optional field and you are not
                required to enter your email to check posture.
            </Alert>
            <Input
                value={userId ?? ""}
                placeholder="Enter Email Address and click the button below"
                onChange={({ detail }) => {
                    setUserId(detail.value);
                }}
                data-id="linkInputField"
            />
            </>}
            <Button
              variant="primary"
              onClick={() => setLoading(true)}
              loading={loading}
            >
              Check Posture Again
            </Button>
        <ul>
            <li className={structure.result}><b>SiteConnect Web Server Configured {structure.reason}</b></li>
            <Collapsible trigger={"Click to show details"} triggerWhenOpen={"Click to hide details"} open={structure.result === 'fail'}>
                <p>
                If you have followed the <Link to="/windows/install.html">Windows Install</Link> then
                you should be able to open&nbsp;
                <Link to="http://127.0.0.1:32182" target="_blank" rel="noopener noreferrer">http://127.0.0.1:32182</Link>
                &nbsp;and view the posture payload that is returned to SiteConnect. If the browser refuses to connect, then
                the web server is <b>not</b> installed correctly.
                </p>

                <p>
                The easiest solution is to just re-run the install script in PowerShell as an Administrator
                which will correctly configure your system:
                <pre><code className="code-block">{codeText}</code></pre>
                <button className="copy-clipboard" type="button" onClick={copyClipboard}>Copy Code</button>
                <br/>

                If this script fails then you must send the following log file to your
                Amazon representative for further troubleshooting:
                <code>%USERPROFILE%\AppData\Local\SiteConnect\configure-siteconnect.log</code>
                </p>
            </Collapsible>
            <li id="os-edition-not-allowed" className={OSType.result}><b>Valid Operating System {OSType.reason}</b></li>
            <Collapsible trigger={"Click to show details"} triggerWhenOpen={"Click to hide details"} open={OSType.result === 'fail'}>
            SiteConnect will only allow Windows editions to connect which currently recieve support and security updates.
            </Collapsible>
            <li id="supported-os" className={supportedOS.result}><b>Compliant Operating System version {supportedOS.reason}</b></li>
            <Collapsible trigger={"Click to show details"} triggerWhenOpen={"Click to hide details"} open={supportedOS.result === 'fail'}>
            Ensure your Operating System and version are supported:
            <table>
                <thead>
                    <th>Operating System</th>
                    <th>Version</th>
                    <th>Edition</th>
                    <th>Supported until</th>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={5}>Windows 10</td>
                        <td rowSpan={1}>1809</td>
                        <td>Enterprise LTSC</td>
                        <td>January 9, 2029</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>21H2</td>
                        <td>Enterprise</td>
                        <td>June 11, 2024</td>
                    </tr>
                    <tr>
                        <td>Enterprise LTSC</td>
                        <td>January 12, 2027</td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>22H2</td>
                        <td>Professional</td>
                        <td rowSpan={2}>October 14, 2025</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                    </tr>
                    <tr>
                        <td rowSpan={7}>Windows 11</td>
                        <td rowSpan={1}>21H2</td>
                        <td>Enterprise</td>
                        <td>October 8, 2024</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>22H2</td>
                        <td>Home</td>
                        <td rowSpan={2}>October 8, 2024</td>
                    </tr>
                    <tr>
                        <td>Professional</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                        <td>October 14, 2025</td>
                    </tr>
                    <tr>
                        <td rowSpan={3}>23H2</td>
                        <td>Home</td>
                        <td rowSpan={2}>November 11, 2025</td>
                    </tr>
                    <tr>
                        <td>Professional</td>
                    </tr>
                    <tr>
                        <td>Enterprise</td>
                        <td>November 10, 2026</td>
                    </tr>
                </tbody>
            </table>
            </Collapsible>
            <li className={firewall.result}><b>Firewall {firewall.reason}</b></li>
            <Collapsible trigger={"Click to show details"} triggerWhenOpen={"Click to hide details"} open={firewall.result === 'fail'}>
            SiteConnect will detect the included OS firewall and most other firewall products which Windows is able to detect.<br/>
            At least one firewall must be detected and enabled.
            </Collapsible>
            <li className={antivirus.result}><b>Antivirus {antivirus.reason}</b></li>
            <Collapsible trigger={"Click to show details"} triggerWhenOpen={"Click to hide details"} open={antivirus.result === 'fail'}>
            SiteConnect will detect the included OS antivirus and most other antivirus products which Windows is able to detect.<br/>
            At least one antivirus must be detected and enabled.
            </Collapsible>
            <h3>Authorization Issues</h3>
            <li id="user-not-authorized"><b>User Not Authorized</b></li>
            <p>
                If you&apos;ve validated that you have all necessary components installed:
                <ol>
                    <li>AWS VPN Client</li>
                    <li>SiteConnect Web Server</li>
                </ol>
                and you are still rejected during a VPN connection attempt, then you are either using
                expired credentials, or an invalid OVPN file.
                Contact your Amazon representative for an updated pair of credentials and OVPN file.

                <br/>
                ⚠️ <b>The credentials expire 15 minutes after creation, and last 8 hours after initial use.</b>
            </p>
        </ul>

        <h2>AWS VPN Client Connection Issues</h2>
        Please ensure you are using the latest version of the AWS VPN Client. See&nbsp; 
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows.html#client-vpn-connect-windows-release-notes">
            AWS VPN Client Release Notes.
        </Link>

        <br/>

        See the&nbsp;
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/windows-troubleshooting.html">
            AWS VPN Client Windows Troubleshooting docs
        </Link>
        &nbsp;for specific problems related to the AWS VPN Client.
        </SpaceBetween>
    </body>
    )
}